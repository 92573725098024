<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path:'/illnessManage' }">疾病管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
            <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-button @click="add('new','')">添加</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="图片" prop="images">
                <template slot-scope="scope">
                    <img slot="reference" :src="scope.row.images" style="width: 130px;">
                </template>
            </el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt doc">
                    <el-button size="mini" type="primary" icon="el-icon-edit" @click="add('bj',scope.row)">编辑</el-button>
                     <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog width="90%" :title="title" :visible.sync="dialogFormVisible">
            <el-form :model="form" label-width="80px">
                <el-form-item label="标题">
                    <el-input style="float: left;" v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <upload2 :width="750" :height="180" :src.sync="form.images"></upload2>
                </el-form-item>
                <el-form-item label="内容">
                    <FullEditor id="1" rows="10" upload="/"></FullEditor>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;form = {}">取 消</el-button>
                <el-button type="primary" @click="sub">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import FullEditor from "../components/FullEditor";
    import upload2 from '../components/upload2';
    export default {
        components: {
            FullEditor, upload2
        },
        inject: ['reload'],
        data() {
            return {
                dialogFormVisible: false,
                list: [],
                ill_lib_id: '',
                name: '',
                title: "添加轮播图",
                form: {
                    images: '',
                    title: '',
                    content: '',
                },
                item: '',
            }
        },
        mounted() {
            this.ill_lib_id = this.$route.query.id;
            // this.form.ill_lib_id = this.$route.query.id;
            this.name = this.$route.query.name;
            this.getdata();
        },
        methods: {
            getdata() {
                this.axios.get("/gu/get_gu_ill_lib_image_by_illlibid?ill_lib_id=" + this.ill_lib_id)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.list = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            htmlEncode: function (html) {
                //1.首先动态创建一个容器标签元素，如DIV
                var temp = document.createElement("div");
                //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
                temp.textContent != undefined
                    ? (temp.textContent = html)
                    : (temp.innerText = html);
                //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
                var output = temp.innerHTML;
                temp = null;
                return output;
            },
            /*2.用浏览器内部转换器实现html解码（反转义）*/
            htmlDecode: function (text) {
                //1.首先动态创建一个容器标签元素，如DIV
                var temp = document.createElement("div");
                //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
                temp.innerHTML = text;
                //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
                var output = temp.innerText || temp.textContent;
                temp = null;
                return output;
            },
            sub() {
                let url = '';
                let str = '';
                if (this.type == 'new') {
                    url = "/gu/insert_gu_ill_lib_image";
                    str = '添加成功'
                } else {
                    url = "/gu/update_gu_ill_lib_image";
                    str = "修改成功";
                    this.form.id = this.item.id;
                }
                this.form.ill_lib_id = this.ill_lib_id;
                this.form.content = this.htmlEncode(tinymce.activeEditor.getContent());
                console.log(this.form);
                this.axios.post(url, this.qs.stringify(this.form))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.dialogFormVisible = false;
                            this.form.title = '';
                            this.form.images = '';
                            this.form.content = '';
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            this.$message(res.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            add(type, row) {
                this.dialogFormVisible = true;
                this.type = type;
                this.item = row;
                if (type != 'new') {
                    this.title = "编辑轮播图";
                    this.form.title = row.title;
                    this.form.images = row.images;
                    let str = this.htmlDecode(row.content);
                    setTimeout(()=>{
                        tinymce.activeEditor.setContent(str);
                    },300)
                } else {
                    this.form.title = '';
                    this.form.images = '';
                    this.form.content = '';
                    setTimeout(()=>{
                        tinymce.activeEditor.setContent('');
                    },300)
                    
                    this.title = '添加轮播图';
                }
            },
            del(row, index) {
                this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_ill_lib_image", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    html .tox-tinymce-aux {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        z-index: 2100;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }
    .el-dialog__header{
        text-align: left;
    }
</style>